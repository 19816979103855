import React, {useEffect, useState} from 'react'
import {useCallApi} from '../../modules/utils'
import {toast} from 'react-toastify'
import {ItemSubVente} from './ItemSubVente'
import {useAuth} from '../../modules/auth'
import {KTSVG} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {FilterCmd} from './FilterCmd'

type Props = {
  bySale: any
}
export const ListSubSale: React.FC<Props> = ({bySale}) => {
  const {date,numCmd,setNumCmd,hasPermission} = useAuth()
  
  console.log(date)

  const [subventes, setsubventes] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [itemsSelected, setItemsSelected] = useState<number>(0)
  const [checkedProductIds, setCheckedProductIds] = useState<any>([]) // State to store checked product IDs
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [filtersubventes, setFiltersubventes] = useState<any>()

  const callApi = useCallApi()
  const getsubsubventes = async (caissier?: any, status?: any, type?: any, payment_status?: any, start_date?: any,
    end_date?:any,returned?:any) => {
    try {

      setLoading(true)
      const currentDate = new Date()
      const formattedDate = currentDate.toLocaleDateString('en-CA')
      const {data} = await callApi({
        route: bySale
          ? `api/${hasPermission("manage_sales_invoiced") && hasPermission("manage_sale") ?"sales":"sale_invoiceds"}?date=${date}&payment_status=${payment_status}&type=${type}&status=${status}&caissier_id=${caissier}&start_date=${start_date}&filter[token]=${numCmd}&is_return=${returned}`
          : (bySale===false ?`api/${hasPermission("manage_sales_invoiced") && hasPermission("manage_sale")?"sales":"sale_invoiceds"}?date=${formattedDate}&payment_status=${payment_status}&type=${type}&status=${status}&caissier_id=${caissier}&start_date=${start_date}&end_date=${end_date}&filter[token]=${numCmd}&is_return=${returned}`:
          `api/sales?date=&payment_status=${payment_status}&type=${type}&status=${status}&caissier_id=${caissier}&start_date=${start_date}&end_date=${end_date}&filter[token]=${numCmd}&is_return=${returned}`),
          
          method: 'GET',
      })

      const initialsubventes = data.data.map((item: any) => ({
        ...item,
        checked: false,
      }))

      setsubventes(initialsubventes)
      // states.setTotalItems(data.meta.total)
      // states.setTotalPages(Math.ceil(states.totalItems / 100))
      setLoading(false)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    const updatedsubventes = filtersubventes.map((product: any) => ({
      ...product,
      checked,
    }))
    if (checked) {
      const checkedProductIds = updatedsubventes.map((product: any) => product.id)
      // Store the checkedProductIds in your table or wherever you need
      setItemsSelected(checkedProductIds.length)
      console.log(checkedProductIds)
    } else {
      setItemsSelected(0)
    }
    const checkedIds = updatedsubventes
      .filter((product: any) => product.checked)
      .map((product: any) => product.id)
    setCheckedProductIds(checkedIds)
    setFiltersubventes(updatedsubventes)
  }

  const handleProductCheckboxChange =
    (productId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked
      const updatedsubventes = filtersubventes.map((product: any) =>
        product.id === productId ? {...product, checked} : product
      )
      setFiltersubventes(updatedsubventes)
      const checkedIds = updatedsubventes
        .filter((product: any) => product.checked)
        .map((product: any) => product.id)
      setCheckedProductIds(checkedIds)
      setItemsSelected(checkedIds.length)
    }
  const deleteProduct = async (ids: number[]) => {
    ids.map(async (id: number) => {
      if (window.confirm('Voulez-vous vraiment supprimer ce produit !' + id)) {
        const filteredposts = subventes.filter((product: any) => product.id !== id)
        setFiltersubventes(filteredposts)
        toast.success('Produit   supprimé')
        const {data} = await callApi({
          route: 'api/subventes/' + id,
          method: 'DELETE',
        })
      }
    })
  }
  const getSubVenteByStatus = (
    caissier: any,
    status: any,
    type: any,
    payment_status: any,
    start_date: any,
    end_date:any,
    returned:any
  ) => {
   
    if (status || caissier || type || payment_status || start_date ||
      end_date ) {
        console.log(returned);

      getsubsubventes(caissier, status, type, payment_status, start_date,
        end_date,returned)
    } else {
  
     
      getsubsubventes(caissier, status, type, payment_status, start_date,
        end_date,returned)    
    }
  }

  useEffect(() => {
    // if(!bySale){
    //   setNumCmd("")
    // }
    getsubsubventes("","","","","","","")
  }, [date, bySale,numCmd])

  useEffect(() => {
    setFiltersubventes(subventes)
  }, [subventes])
  return (
    <>
      <div className={`card }`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Commandes</span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <>
              {/* begin::Filter Button */}

              {/* end::SubMenu */}
            </>
            {itemsSelected != 0 ? (
              <div className='d-flex justify-content-end align-items-center'>
                <div className='fw-bolder me-5'>
                  <span className='me-2'>{itemsSelected}</span> Selected
                </div>
                <button
                  type='button'
                  className='btn  btn-sm btn-danger'
                  onClick={() => {
                    deleteProduct(checkedProductIds)
                  }}
                >
                  Supprimer les sélectionnés
                </button>
              </div>
            ) : (
              <>
                <FilterCmd bysale={false} getSubVenteByStatus={getSubVenteByStatus} />
              </>
            )}
          {
            bySale?<Link to={'/ventes/journal-de-vente'} className='btn btn-sm btn-primary shadow-sm mr-1'>
            <i className='fas fa-arrow-circle-left'></i>
            <span className='d-none d-sm-inline-block font-weight-bold'>Retour</span>
          </Link>:<></>
          }
            
          </div>
        </div>
        {loading ? (
          <div className='d-flex justify-content-center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>
                      <div className='form-check form-check-custom form-check-solid mx-5'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          checked={filtersubventes.every((product: any) => product.checked)}
                          onChange={handleHeaderCheckboxChange}
                        />
                      </div>
                    </th>
                    <th className='min-w-150px '>N° de vente</th>
                    {/* <th className="min-w-150px">Pays</th> */}
                    <th className='min-w-150px '>Vendeur/Caisse</th>
                    <th className='min-w-150px text-center'>Type</th>
                    <th className='min-w-150px text-center'>Date</th>
                    <th className='min-w-150px text-center'>Total</th>
                    <th className='min-w-150px text-center'>Statut</th>
          
                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {filtersubventes.map((product: any, index: any) => {
                    return (
                      <ItemSubVente
                        // deleteProduct={deleteProduct}
                        key={index}
                        data={product}
                        handleProductCheckboxChange={handleProductCheckboxChange}
                      />
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* <Pagination count={totalPages} page={currentPage} color="primary" onChange={handlePageChange}  className="d-flex justify-content-center"/> */}
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
        )}
        {/* end::Header */}
        {/* begin::Body */}

        {/* begin::Body */}
      </div>
    </>
  )
}
