import React from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {ModalUpdateProduct} from '../produits/ModalUpdateProduct'
import {Link} from 'react-router-dom'
import {useAuth} from '../../modules/auth'

type Props = {
  data: any
  handleProductCheckboxChange: any
  deleteProduct?: (id: number) => void
}
export const ItemVentes: React.FC<Props> = ({data, handleProductCheckboxChange, deleteProduct}) => {
  const {setDate} = useAuth()
  return (
    <>
      <tr>
        <td>
          {' '}
          <div className='form-check form-check-custom form-check-solid mx-5'>
            <input
              type='checkbox'
              className='form-check-input'
              checked={data.checked}
              onChange={handleProductCheckboxChange(data.id)}
            />
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            {/* / */}

            <div className='d-flex justify-content-start flex-column'>
              <input className='d-none' type='text' value={data.id} />
              <span className=' fw-bold  badge badge-primary  me-auto px-2 py-3 d-block fs-7'>
                #{data.id}
              </span>{' '}
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-start flex-column'>
            <span className='text-dark fw-bold  d-block fs-6'>{data.date.substring(0, 10)}</span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center text-center flex-column'>
            <span className='text-dark fw-bold  d-block fs-6'>{data.date.substring(0, 10)}</span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center flex-column'>
            <span className='text-dark fw-bold text-center d-block fs-6'>
              <div className='card-toolbar ml-3'>
                {data.total == 0 || data.total == null ? <>0</> : data.total}
              </div>
            </span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <div className='d-flex justify-content-end flex-shrink-0'>
              {/* <button
                type='button'
                data-bs-toggle='modal'
                data-bs-target={'#modalupdateproduct' + data.id}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
              </button> */}
              {/* <ModalUpdateProduct data={data}/> */}
              {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button>

<div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        ...
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>   */}

              <Link
                onClick={() => setDate(data.date.substring(0, 10))}
                to={{
                  pathname: '/journal-ventes/details',
                  // search: `?product_price=${data.attributes.product_price}&price_purchase_ttc=${data.attributes.price_purchase}`,
                }}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
              </Link>

              {/* <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      // onClick={()=>deleteProduct(data.id)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a> */}
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}
