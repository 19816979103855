import React from 'react'
import { useCallApi } from '../../modules/utils'
import { toast } from 'react-toastify'
import { ItemDetailsSubVente } from './ItemDetailsSubVente'


type Props = {
    data: any
  
    // deleteVille:(id:number)=>void
  }
export const ModalDetailsSubVente:React.FC<Props> = ({data}) => {
    const callApi = useCallApi()

//  const saveData =async() => {
//     try {
//       // setSenddata(data.attributes)
//       console.log(data)
//       getdata["status"]=dataUpdated.status
//       console.log(getdata)
//       const dat = await callApi({
//         route: `api/purchases/`+data.id,
//         method: 'PUT',
//         body:getdata ,
//       })
//        toast.success("ajoute avec succes")
//       //  setLoading(false)mo
//      } catch (error:any) {
//       toast.error(error.response.data.message)
//       // setLoading(false)
//      }
   

//   }
  return (
    <>
 <div className="modal fade" id={"modaldetailssubventes"+data.id} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" style={{maxWidth: '61%'}}>
        <div className="modal-content">
          <div className="modal-header bg-primary">
            <h1 className="modal-title fs-5 " id="exampleModalLabel">Commande {data.id}</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          <div className="row ">
    <div className="col-12 border-left-primary col-sm-12 col-md-12 col-lg-6 mb-2">
        <div className="card border-left-primary shadow h-100" style={{borderLeft:"6px solid #4e73df"}}>
            <div className="card-body">
                <div className="row">
                    <div className="fw-bold text-100 text-primary text-uppercase mb-1 col">N° de Commande:{data.id}</div>
                </div>
                <div className="mb-0 fw-bold text-gray-700">Crée le: {new Date(data.attributes.created_at).toISOString().slice(0, 10)+" "+new Date(data.attributes.created_at).toLocaleTimeString()}   </div>
                <div className="mb-0 fw-bold text-gray-700">Crée Par: 
                <span className='text-uppercase'>{data.attributes.caissier_name}</span> </div>
                <div className="mb-0 fw-bold text-gray-700">Total: {data.attributes.grand_total} DH</div>
            </div>
        </div>
    </div>

    <div className="col-12  border-left-primarycol-sm-12 col-md-12 col-lg-6 mb-2">
        <div className="card border-left-success shadow h-100" style={{borderLeft:"6px solid #1cc88a"}}>
            <div className="card-body">
                <div className="fw-bold  text-xs text-success text-uppercase mb-1">Détail de Commande</div>
                <div className="row">
                    <div className="text-gray-900 col-auto fw-bold">Statut:</div>
                    <div className="col">
                        <select className="form-control  form-control-sm" data-val="true" data-val-required="The OrderStatus field is required."
                        id="OrderStatus" name="OrderStatus" >
                          <option  value={1} selected={data.attributes.status}>{data.attributes.status}</option>
                 
                             </select>
                    </div>
                </div>
                <div className="row mt-2">
                <div className="text-gray-900 col-auto fw-bold ">Paiement:</div>
                    <div className="col-2">
                        { <span className={
                            `badge fw-bold ${
                                data.attributes.payment_status == 1 ? 'badge-light-success' :'badge-light-danger' 
                                }`
                        }>
                        {                                data.attributes.payment_status == 1 ? 'Payé' :'non Payé' 
}
                    </span>}
                    </div>
                    <div className="col-4">
                        { <span className={
                            `badge fw-bold badge-light-primary`
                        }>
                        {                                data.attributes.payment_type
}
                    </span>}
                    </div>
                </div>
                <div className="row mt-2">
                <div className="text-gray-900 col-auto fw-bold ">Encaissée par:</div>
                    <div className="col">
                    <span className='text-uppercase'>{data.attributes.caissier_name}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
   

         <ItemDetailsSubVente data={data}/>

  
   
</div>

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            {/* <button type="button" className="btn btn-primary" >Save changes</button> */}
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
