import * as React from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { AnyCnameRecord } from 'dns';

type Props = {
  products?: any;
  idProduct?: number;
  index?:number
  setProduct:(id:any)=>void
  updatePurchaseItem?: CallableFunction
};

const ComboBox: React.FC<Props> = ({ setProduct,products, idProduct ,updatePurchaseItem,index}) => {
  const [selectedProduct, setSelectedProduct] = useState<any>(null);

  React.useEffect(() => {
    const idProd = products.find((product: any) => product.id == idProduct);
    setSelectedProduct(idProd);
    setProduct(idProd);
   console.log(products);
 
    
  }, [products, idProduct]);
  
  console.log(selectedProduct);
  
  return (
    <Autocomplete
    className='text-dark fw-bold  d-block fs-6'
      disablePortal
      id="combo-box-demo"
      options={products}
      getOptionLabel={(option) => option.attributes.code + " - " + option.attributes.name}
      value={selectedProduct}
      onChange={(event, newValue) => {
        updatePurchaseItem && updatePurchaseItem(index,"quantity",0)
        updatePurchaseItem &&   updatePurchaseItem(index,"sub_total",0)
        setSelectedProduct(newValue)
       updatePurchaseItem && updatePurchaseItem(index,"product_id",newValue?.id)
      }}
      sx={{ width: 300}}
    
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

export default ComboBox;
