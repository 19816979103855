import React, { useEffect, useState } from 'react'
import {CardDetails} from '../../components/produits/CardDetails'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useCallApi } from '../../modules/utils';
import { log } from 'console';



export const ProductDetails = () => {
  const [product, setProduct] = useState<any>();
  const [loading, setLoading] = useState(true)
  const {id}=useParams()
  const callApi = useCallApi()
  const getProduct = async () => {
    try {
      const { data } = await callApi({
        route: "api/products/"+id,
        method: "GET",
      });
      
   

      setProduct(data.data);
      console.log(data.data)
      // states.setTotalItems(data.meta.total);
      // states.setTotalPages(Math.ceil( states.totalItems / 4)); 
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };
  useEffect(() => {
    getProduct();
  }, []);
  return (
    <>
    <div id="list-title" className="d-flex align-items-center justify-content-between mb-3">
    <h4 className=" mr-auto">{`Fiche Technique: ${product?.attributes.name} (${product?.attributes.product_category_name})`}</h4>

    <Link to={"/products"}  className="btn btn-sm btn-primary shadow-sm mr-1">
        <i className="fas fa-arrow-circle-left"></i>
        <span className="d-none d-sm-inline-block font-weight-bold">Retour</span>
    </Link>
    {/* <a href="#" className="btn btn-sm btn-primary shadow-sm">
        <i className="fas fa-sync"></i>
        <span className="d-none d-sm-inline-block font-weight-bold">Actualiser</span>
    </a> */}
</div>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <CardDetails
            className='card-xl-stretch mb-xl-8'
            svgIcon='fa-money-check-alt'
            color='body-white'
            iconColor='primary'
            title='PRIX DE VENTE TTC'
            description={product?.attributes.product_price}
            titleColor='primary'
            descriptionColor='gray-400'
            borderleftColor='#4e73df'
          />
        </div>

        <div className='col-xl-4'>
          <CardDetails
            className='card-xl-stretch mb-xl-8'
            svgIcon='fa-shopping-bag'
            color='success'
            iconColor='success'
            title='PRIX DE VENTE HT'
            description={product?.attributes.product_price}
            titleColor='success'
            descriptionColor='white'
            borderleftColor='#4e73df'
          />
        </div>

        <div className='col-xl-4'>
          <CardDetails
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='fa-clipboard-list'
            color='dark'
            iconColor='info'
            title="PRIX D'ACHAT"
            description={product?.attributes.price_purchase}
            titleColor='info'
            descriptionColor='gray-100'
            borderleftColor='#4e73df'
          />
        </div>
        <div className='col-xl-4'>
          <CardDetails
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='fa-sack-dollar'
            color='danger'
            iconColor='danger'
            title='BENIFICES'
            description={product?.attributes.product_price-product?.attributes.price_purchase}
            titleColor='danger'
            descriptionColor='gray-100'
            borderleftColor='#4e73df'
          />
        </div>
      </div>
    </>
  )
}
