import {Field, Form, Formik} from 'formik'
import React, {ChangeEvent, useState} from 'react'
import {createFormData, useCallApi} from '../../modules/utils'
import {useAuth} from '../../modules/auth'
import {Loading} from '../Loading'
import {toast} from 'react-toastify'


import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

const PrettoSlider = styled(Slider)({
    color: '#009ef7',
    height: 8,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#009ef7',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  });


export const ModalAddpromotion = () => {
  const {categories} = useAuth()
  const callApi = useCallApi()
  const [loading, setLoading] = useState(false)
  const [chejed, setchejed] = useState(false)
  const [value, setValue] = useState(20);

  const handleChange = (e:any) => {
    setValue(e.target.value);
    console.log(e.target.value);
    
  };
  console.log(value);
  
  const initialValues = {

    description: '',
    start_date: '',
    end_date: '',
    discount: 0,
  }

  const handelSumit = async (values: any) => {
    // setLoading(true)
    // const dataTosend = {
    //   description: values.description,

    //    start_date:  values.start_date,
    //   end_date:  values.start_date,
    //  discount:  value,
    // }
   const data = {...values,discount:value}
    console.log(data);

  
    try {
      const dat = await callApi({
        route: `api/promotions`,
        method: 'POST',
        body: data,
      })
      toast.success('ajoute avec succes')
      setLoading(false)
    } catch (error: any) {
      toast.error(error.response.data.message)
      setLoading(false)
    }
  }
  return (
    <>
      <div
        className='modal fade'
        id='modaladdpromotion'
        role='dialog'
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document' style={{maxWidth: '40%'}}>
          <Formik
            initialValues={initialValues}
            onSubmit={(values: any, {resetForm}) => {
              handelSumit(values)
              //   resetForm()
              //   setFiles("")
            }}
          >
            {() => (
              <Form className='modal-content'>
                <div className='modal-content'>
                  <div className='modal-header ' style={{background: 'var(--kt-primary)'}}>
                    <h1 className='modal-title fs-5 text-white' id='exampleModalLabel'>
                      Nouvelle Promotion :
                    </h1>
                    <button
                      type='button'
                      className='btn-close text-white'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    ></button>
                  </div>
                  <div className='modal-body'>
                    <form>
                      <div className='row mb-4'>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Description :
                            </label>
                            <Field
                              name='description'
                              type='text'
                              className='form-control  form-control-solid'                               id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                        
                      </div>
                      <div className='row mb-4'>
                        <div className='form-group'>
                          <label htmlFor='exampleInputEmail1' className='fw-bold'>
                            Taux de promotion :
                          </label>
                          <Box sx={{ width: 320 }}>
      <PrettoSlider
        value={value}
        onChange={(e:any)=>handleChange(e)}
        valueLabelDisplay="auto"
        aria-label="pretto slider"
        // defaultValue={20}
      />
      <Typography>{value } %</Typography>
    </Box>
                        </div>
                      </div>
                      <div className='row mb-4'>
                        <div className="col">
                        <div className='form-group'>
                          <label htmlFor='exampleInputEmail1' className='fw-bold'>
                            Date début :
                          </label>
                          <Field
                            type='datetime-local'
                            name='start_date'
                            className='form-control  form-control-solid'                            id='exampleInputEmail1'
                            aria-describedby='text'
                          />
                        </div>
                        </div>
                        <div className="col">
                        <div className='form-group'>
                          <label htmlFor='exampleInputEmail1' className='fw-bold'>
                            Date fin :
                          </label>
                          <Field
                            type='datetime-local'
                            name='end_date'
                            className='form-control  form-control-solid'                            id='exampleInputEmail1'
                            aria-describedby='text'
                          />
                        </div>
                        </div>
                     
                      </div>

                    

                   
                    </form>
                  </div>
                  <div className='modal-footer'>
                    <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                      Annuler
                    </button>
                    {loading ? (
                      <button type='button' className='btn btn-primary'>
                        <Loading />
                      </button>
                    ) : (
                      <button type='submit' className='btn btn-primary'>
                        valider
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
