import React, {ChangeEvent, useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {createFormData, useCallApi} from '../../modules/utils'
import {number, string} from 'yup'
import {ToastContainer, toast} from 'react-toastify'
import {Loading} from '../Loading'
import {check} from 'prettier'
import {useAuth} from '../../modules/auth'

type Props = {
  data: any

  // deleteVille:(id:number)=>void
}
export const ModalUpdateCharge: React.FC<Props> = ({data}) => {
  const {categories} = useAuth()
  const callApi = useCallApi()
  const [image, setImage] = useState<File | any>([])
  const [typeCharge, setTypeCharge] = useState<any>([])

  const [filess, setFiles] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [chejed, setchejed] = useState(data.attributes.status === '1')
  const [roles, setRoles] = useState<any>([])

  const [utilisateurUpdate, setUtilisateurUpdate] = useState({
    amount: data.attributes.amount,
    expense_category_id: data.attributes.expense_category_id,
    details: data.attributes.details,
    status: data.attributes.status,
  })

  const updateProduct = async () => {
    setLoading(true)
    const dataTosend = {
      expense_category_id: utilisateurUpdate.expense_category_id,
      amount: utilisateurUpdate.amount,
      date: new Date().toLocaleDateString(),
      details: utilisateurUpdate.details,
      status: utilisateurUpdate.status,
    }
    console.log(dataTosend)

    // const formData = createFormData(dataTosend, image)
    try {
      const dat = await callApi({
        route: `api/expenses/` + data.id,
        method: 'PUT',
        body: dataTosend,
      })
      toast.success('modifié avec succes')
      setLoading(false)
    } catch (error: any) {
      toast.error(error.response.data.message)
      setLoading(false)
    }
  }
  const gettypeCharge = async () => {
    try {
      const data = await callApi({
        route: 'api/expense-categories',

        method: 'GET',
      })
      setTypeCharge(data.data.data)
    } catch (error: any) {
      toast.error(error.response.data.message)
    }
  }

  useEffect(() => {
    gettypeCharge()
  }, [])
  return (
    <>
      <div
        className='modal fade'
        id={'ModalUpdateUtilisateur' + data.id}
        tabIndex={-1}
        role='dialog'
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document' style={{maxWidth: '50%'}}>
          <div className='modal-content'>
            <div className='modal-header ' style={{background: 'var(--kt-primary)'}}>
              <h1 className='modal-title fs-5 text-white' id='exampleModalLabel'>
                Modification charge N°:
                {data.id}
              </h1>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <form>
                <div className='form-group mb-5  '></div>
                <div className='row mb-4'>
                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        {' '}
                        Type de charge :
                      </label>
                      <select
                        name='expense_category_id'
                        className='form-select'
                        aria-label='Default select example'
                        value={utilisateurUpdate.expense_category_id}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          setUtilisateurUpdate({
                            ...utilisateurUpdate,
                            expense_category_id: e.target.value,
                          })
                        }
                      >
                        <option value=''>Choisir..</option>
                        {typeCharge &&
                          typeCharge.map((charge: any, index: number) => {
                            return (
                              <option
                                key={index}
                                value={charge.id}
                                selected={data.attributes.expense_category_id === charge.id}
                              >
                                {charge.attributes.name}
                              </option>
                            )
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className='row mb-4'>
                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        Note :
                      </label>
                      <input
                        name='details'
                        type='text'
                        value={utilisateurUpdate.details}
                        className='form-control'
                        id='exampleInputEmail1'
                        aria-describedby='text'
                        onChange={(e: any) =>
                          setUtilisateurUpdate({
                            ...utilisateurUpdate,
                            details: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='row mb-4'>
                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        Total TTC :
                      </label>
                      <input
                        name='amount'
                        type='number'
                        value={utilisateurUpdate.amount}
                        className='form-control'
                        id='exampleInputEmail1'
                        aria-describedby='text'
                        onChange={(e: any) =>
                          setUtilisateurUpdate({
                            ...utilisateurUpdate,
                            amount: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='row mb-4'>
                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        Total TVA :
                      </label>
                      <input
                        name='amount'
                        type='number'
                        className='form-control'
                        id='exampleInputEmail1'
                        aria-describedby='text'
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col'>
                    <div className='form-group d-flex fw-bold'>
                      {' '}
                      <label htmlFor='exampleInputEmail1'>Statut :</label>
                      <div className='form-check form-switch'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          role='switch'
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const {checked} = e.target
                            setchejed(checked)
                            console.log(checked)

                            setUtilisateurUpdate({...utilisateurUpdate, status: checked ? 1 : 0})
                          }}
                          checked={chejed}
                          id='flexSwitchCheckDefault'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                Annuler
              </button>
              {loading ? (
                <button type='button' className='btn btn-primary'>
                  <Loading />
                </button>
              ) : (
                <button type='button' onClick={updateProduct} className='btn btn-primary'>
                  valider
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
