import React, {useEffect, useState} from 'react'
import {useCallApi} from '../../../../app/modules/utils'

type Props = {
  className: string
}

const ListsWidget6: React.FC<Props> = ({className}) => {
  const [todaySalles, setTodaySalles] = useState<any>([])
  const callApi = useCallApi()

  const getSAlles = async () => {
    try {
      const data: any = await callApi({
        route: 'api/top-selling-products',
        method: 'GET',
      })
      console.log(data.data.data)
      setTodaySalles(data.data.data)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  useEffect(() => {
    getSAlles()
  }, [])

  const maxSale =
    todaySalles.length > 0 ? Math.max(...todaySalles.map((sale: any) => sale.grand_total)) : 0

  return (
    <div className={`card card-xl-stretch mb-5 mb-xl-8 ${className}`}>
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>Produits les plus vendus</h3>
        <div className='card-toolbar'></div>
      </div>
      <div className='card-body pt-0'>
        {todaySalles.map((todaySalle: any, index: number) => {
          const progressWidth =
            index === 0 ? '100%' : `${(todaySalle.grand_total / maxSale) * 100}%`
          return (
            <div className='d-flex rounded p-5 mb-7 flex-column' key={index}>
              <div className='d-flex justify-content-between mb-2'>
                <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
                  {todaySalle.name}
                </a>
                <span className='fw-bold text-success py-1'>{todaySalle.grand_total} MAD</span>
              </div>
              <div className='progress h-8px w-100'>
                <div
                  className='progress-bar bg-primary'
                  role='progressbar'
                  style={{width: progressWidth}}
                ></div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export {ListsWidget6}
