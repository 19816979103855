import {Field, Form, Formik} from 'formik'
import React, {useState} from 'react'
import {createFormData, useCallApi} from '../../modules/utils'
import {useAuth} from '../../modules/auth'

import {Loading} from '../Loading'
import {toast} from 'react-toastify'

export const ModalAddFournisseur = () => {
  const {categories} = useAuth()
  const callApi = useCallApi()
  const [loading, setLoading] = useState(false)
  const [chejed, setchejed] = useState(false)

  const initialValues = {
    name: '',
    code: '',
    product_category_id: '',
    product_price: 0,
    notes: '',
    status: true,
    price_purchase: 0,
    nbrpoints: 0,
  }
  const [image, setImage] = useState<File | any>([])

  const [filess, setFiles] = useState<string>()
  const handelSumit = async (values: any) => {
    // setLoading(true)
    const dataTosend = {
      email: values.email,
      name: values.name,
      address: values.address,
      city: values.city,
      phone: values.phone,
      country: 'maroc',
      status: values.status == true ? 1 : 0,
    }

    const formData = createFormData(dataTosend, image)
    console.log(dataTosend)

    try {
      const dat = await callApi({
        route: `api/suppliers`,
        method: 'POST',
        body: formData,
      })
      toast.success('ajoute avec succes')
      setLoading(false)
    } catch (error: any) {
      toast.error(error.response.data.message)
      setLoading(false)
    }
  }
  return (
    <>
      <div
        className='modal fade'
        id='ModalAddCategorie'
        role='dialog'
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document' style={{maxWidth: '40%'}}>
          <Formik
            initialValues={initialValues}
            onSubmit={(values: any, {resetForm}) => {
              handelSumit(values)
              //   resetForm()
              //   setFiles("")
            }}
          >
            {() => (
              <Form className='modal-content'>
                <div className='modal-content'>
                  <div className='modal-header ' style={{background: 'var(--kt-primary)'}}>
                    <h1 className='modal-title fs-5 text-white' id='exampleModalLabel'>
                      Nouveau Fournisseur:
                    </h1>
                    <button
                      type='button'
                      className='btn-close text-white'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    ></button>
                  </div>
                  <div className='modal-body'>
                    <form>
                      <div className='row mb-4'>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Nom :
                            </label>
                            <Field
                              name='name'
                              type='text'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              Nom du contact :
                            </label>
                            <Field
                              name='name'
                              type='text'
                              className='form-control'
                              id='name'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='form-group col'>
                          <label htmlFor='exampleInputEmail1' className='fw-bold'>
                            Tel :
                          </label>
                          <Field
                            name='phone'
                            type='phone'
                            className='form-control'
                            id='exampleInputEmail1'
                            aria-describedby='text'
                          />
                        </div>
                        <div className='form-group col'>
                          <label htmlFor='exampleInputEmail1' className='fw-bold'>
                            email :
                          </label>
                          <Field
                            name='email'
                            type='email'
                            className='form-control'
                            id='exampleInputEmail1'
                            aria-describedby='text'
                          />
                        </div>
                      </div>

                      <div className='form-group'>
                        <label htmlFor='exampleInputEmail1' className='fw-bold'>
                          Ville :
                        </label>
                        <Field
                          name='city'
                          type='text'
                          className='form-control'
                          id='exampleInputEmail1'
                          aria-describedby='text'
                        />
                      </div>
                      <div className='form-group'>
                        <label htmlFor='exampleInputEmail1' className='fw-bold'>
                          Adress :
                        </label>
                        <Field
                          name='address'
                          type='text'
                          className='form-control'
                          id='exampleInputEmail1'
                          aria-describedby='text'
                        />
                      </div>
                      <div className='form-group'>
                        <label htmlFor='exampleInputEmail1' className='fw-bold'>
                          Descreption :
                        </label>
                        <Field
                          name='notes'
                          as={'textarea'}
                          rows={3}
                          className='form-control'
                          id='exampleInputEmail1'
                          aria-describedby='text'
                        />
                      </div>
                      <div className='form-group d-flex fw-bold mt-4 '>
                        {' '}
                        <label htmlFor='exampleInputEmail1 '>Statut </label>
                        <div className='form-check form-switch ms-4'>
                          <Field
                            className='form-check-input'
                            type={'checkbox'}
                            name={'status'}
                            role='switch'
                            //   onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            //     const { checked } = e.target;
                            //     setchejed(checked)
                            //   }}
                            id='flexSwitchCheckDefault'
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className='modal-footer'>
                    <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                      Annuler
                    </button>
                    {loading ? (
                      <button type='button' className='btn btn-primary'>
                        <Loading />
                      </button>
                    ) : (
                      <button type='submit' className='btn btn-primary'>
                        valider
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
