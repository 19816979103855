import React from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import { ModalUpdateProimotions } from './ModalUpdateProimotions'
import { ModalAttachPromo } from './ModalAttachPromo'
import { MdOutlineVisibility } from "react-icons/md";
import { ModalProductPromotions } from './ModalProductPromotions'
type Props = {
  data: any
  handlepromotionCheckboxChange: any
}
export const ItemPromotions: React.FC<Props> = ({data, handlepromotionCheckboxChange}) => {
  return (
    <>
      <tr>
        <td>
          {' '}
          <div className='form-check form-check-custom form-check-solid mx-5'>
            <input
              type='checkbox'
              className='form-check-input'
              checked={data.checked}
              onChange={handlepromotionCheckboxChange(data.id)}
            />
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            {/* / */}

            <div className='d-flex justify-content-start flex-column'>
              <input className='d-none' type='text' value={data.id} />
              <span className='text-dark fw-bold  d-block fs-6'>{data.description}</span>
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center w-100'>
            <div className='progress h-6px w-100 me-2 bg-light-success'>
              <div
                className={`progress-bar ${
                  data.discount >= 30
                    ? 'bg-warning'
                    : data.discount >= 50
                    ? 'bg-danger'
                    : 'bg-primary'
                }`}
                role='progressbar'
                style={{width: data.discount+"%"}}
                aria-valuenow={data.discount}
                aria-bs-valuemin='0'
                aria-bs-valuemax='100'
              ></div>
            </div>

            <span className='text-gray-400 fw-semibold'>{data.discount + '%'}</span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center flex-column'>
            {}
            <span className='text-dark fw-bold text-center d-block fs-6'>
              <div className='card-toolbar ml-3'>
                <span className={' badge badge-light-primary fw-bolder me-auto px-2 py-3'}>
                  {data.start_date}
                </span>
              </div>
            </span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center flex-column'>
            {}
            <span className='text-dark fw-bold text-center d-block fs-6'>
              <div className='card-toolbar ml-3'>
                <span className={' badge badge-light-danger fw-bolder me-auto px-2 py-3'}>
                  {data.end_date}
                </span>
              </div>
            </span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                type='button'
                data-bs-toggle='modal'
                data-bs-target={'#ModalUpdatePromo' + data.id}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
              </button>
              <ModalUpdateProimotions data={data}/>
              {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
    Launch demo modal
  </button>
  
  <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
          ...
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" className="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>   */}

<button
                type='button'
                data-bs-toggle='modal'
                data-bs-target={'#modalattachpromo' + data.id}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />

              </button>
              <ModalAttachPromo data={data}/>
              <button
                type='button'
                data-bs-toggle='modal'
                data-bs-target={'#ModalPoductPromo' + data.id}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                        <KTSVG
                          path='/media/icons/duotune/general/genoeil.svg'
                          className='svg-icon-3'
                        />

                      </button>
                      <ModalProductPromotions data={data}/>
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}
