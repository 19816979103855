import React, {useEffect, useState} from 'react'
import {useCallApi} from '../../modules/utils'
import {KTSVG} from '../../../_metronic/helpers'
import {ToastContainer, toast} from 'react-toastify'
import SearchComponent from '../SearchComponent'
import { ItemPromotions } from './ItemPromotions'
import { ModalAddpromotion } from './ModalAddPromotion'

type Props = {
  states: any
  // deleteVille:(id:number)=>void
}
export const ListPromotions: React.FC<Props> = ({states}) => {
  const [promotions, setPromotions] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [itemsSelected, setItemsSelected] = useState<number>(0)
  const [checkedpromotionIds, setCheckedpromotionIds] = useState<any>([]) // State to store checked promotion IDs
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [filterpromotion, setFilterpromotion] = useState<any>([])

  const callApi = useCallApi()
  const getpromotions = async () => {
    try {
      const {data} = await callApi({
        route: `api/promotions?page[size]=10&page[number]=${states.currentPage}&filter[search]=${searchTerm}`,
        method: 'GET',
      })

      const initialpromotions = data.data.map((item: any) => ({
        ...item,
        checked: false,
      }))
      console.log(data)

      setPromotions(initialpromotions)
      states.setTotalItems(data.total)
      states.setTotalPages(Math.ceil(data.total / data.per_page))
      setLoading(false)
    } catch (error) {
      console.error('Error fetching promotion data:', error)
    }
  }

  const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    const updatedpromotions = filterpromotion.map((promotion: any) => ({
      ...promotion,
      checked,
    }))
    if (checked) {
      const checkedpromotionIds = updatedpromotions.map((promotion: any) => promotion.id)
      // Store the checkedpromotionIds in your table or wherever you need
      setItemsSelected(checkedpromotionIds.length)
      console.log(checkedpromotionIds)
    } else {
      setItemsSelected(0)
    }
    const checkedIds = updatedpromotions
      .filter((promotion: any) => promotion.checked)
      .map((promotion: any) => promotion.id)
    setCheckedpromotionIds(checkedIds)
    setFilterpromotion(updatedpromotions)
  }

  const handlepromotionCheckboxChange =
    (promotionId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked
      const updatedpromotions = filterpromotion.map((promotion: any) =>
        promotion.id === promotionId ? {...promotion, checked} : promotion
      )
      setFilterpromotion(updatedpromotions)
      const checkedIds = updatedpromotions
        .filter((promotion: any) => promotion.checked)
        .map((promotion: any) => promotion.id)
      setCheckedpromotionIds(checkedIds)
      setItemsSelected(checkedIds.length)
    }
  const deletepromotion = async (ids: number[]) => {
    ids.map(async (id: number) => {
      if (window.confirm('Voulez-vous vraiment supprimer cette Promotion !')) {
        const filteredposts = promotions.filter((promotion: any) => promotion.id !== id)
        setFilterpromotion(filteredposts)
        toast.success('Promotion   supprimé')
        const {data} = await callApi({
          route: 'api/promotions/' + id,
          method: 'DELETE',
        })
      }
    })
  }

  const getpromotionByName = async () => {

    try {
      const {data} = await callApi({
        route: `api/promotions?page[size]=10&page[number]=1&filter[search]=${searchTerm}`,
        method: 'GET',
      })

      const initialpromotions = data.data.map((item: any) => ({
        ...item,
        checked: false,
      }))
      console.log(data)

      setPromotions(initialpromotions)
      states.setTotalItems(data.total)
      states.setTotalPages(Math.ceil(data.total / data.per_page))
      setLoading(false)
    } catch (error) {
      console.error('Error fetching promotion data:', error)
    }
  }

  useEffect(() => {
    getpromotions()
  }, [states.currentPage])
  useEffect(() => {
    setFilterpromotion(promotions)
  }, [promotions])
  useEffect(() => {
    getpromotionByName()
  }, [searchTerm])
  // useEffect(() => {
  //   getPurchaseByName()
  // }, [searchTerm])
  return (
    <>
    <ToastContainer/>
      <div className={`card }`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Promotions
              <SearchComponent
                placeholder={'Recherche Promotion'}
                searchQuery={[searchTerm, setSearchTerm]}
              />
            </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <>
              {/* begin::Filter Button */}
              <button
                // disabled={isLoading}
                type='button'
                className='btn btn-light-primary me-3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                Filter
              </button>
              {/* end::Filter Button */}
              {/* begin::SubMenu */}
              <div
                className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
                data-kt-menu='true'
              >
                {/* begin::Header */}
                <div className='px-7 py-5'>
                  <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                </div>
                {/* end::Header */}

                {/* begin::Separator */}
                <div className='separator border-gray-200'></div>
                {/* end::Separator */}

                {/* begin::Content */}
                <div className='px-7 py-5' data-kt-user-table-filter='form'>
                  {/* begin::Input group */}
                  <div className='mb-10'>
                    <label className='form-label fs-6 fw-bold'>Statut:</label>
                    <select
                      className='form-select form-select-solid fw-bolder'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      data-kt-user-table-filter='role'
                      data-hide-search='true'
                    >
                      <option value=''>Tous</option>
                      <option value='1'>Activé</option>
                      <option value='0'>Desactivé</option>
                    </select>
                  </div>
                  {/* end::Input group */}

                  {/* begin::Input group */}

                  {/* end::Input group */}

                  {/* begin::Actions */}
                  <div className='d-flex justify-content-end'>
                    <button
                      type='button'
                      // disabled={isLoading}
                      // onClick={filterData}
                      className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                      data-kt-menu-dismiss='true'
                      data-kt-user-table-filter='reset'
                    >
                      Reset
                    </button>
                    <button
                      // disabled={isLoading}
                      type='button'
                      // onClick={resetData}
                      className='btn btn-primary fw-bold px-6'
                      data-kt-menu-dismiss='true'
                      data-kt-user-table-filter='filter'
                    >
                      Apply
                    </button>
                  </div>
                  {/* end::Actions */}
                </div>
                {/* end::Content */}
              </div>
              {/* end::SubMenu */}
            </>
            {itemsSelected != 0 ? (
              <div className='d-flex justify-content-end align-items-center'>
                <div className='fw-bolder me-5'>
                  <span className='me-2'>{itemsSelected}</span> Selected
                </div>
                <button
                  type='button'
                  className='btn  btn-sm btn-danger'
                  onClick={() => {
                    deletepromotion(checkedpromotionIds)
                  }}
                >
                  Supprimer les sélectionnés
                </button>
              </div>
            ) : (
              <>
                {' '}
                <button
                  type='button'
                  className='btn btn-sm btn-primary '
                  data-bs-toggle='modal'
                  data-bs-target='#modaladdpromotion'
                >
                  <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2' />
                  Ajouter Promotion
                </button>
                <ModalAddpromotion />
              </>
            )}
          </div>
        </div>
        {loading ? (
          <div className='d-flex justify-content-center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>
                      <div className='form-check form-check-custom form-check-solid mx-5'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          checked={filterpromotion.every((promotion: any) => promotion.checked)}
                          onChange={handleHeaderCheckboxChange}
                        />
                      </div>
                    </th>
                    {/* <th className="min-w-150px">Pays</th> */}
                    <th className='min-w-150px '>DESCRIPTION</th>
                    <th className='min-w-150px text-center'>TAUX DE PROMOTION</th>
                    <th className='min-w-150px text-center'>COMMENCE LE</th>
                    <th className='min-w-150px text-center'>EXPIRE LE</th>
                    <th className='min-w-100px text-end'>ACTIONS</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {filterpromotion.map((promotion: any, index: any) => {
                    return (
                      <ItemPromotions
                        // deletepromotion={deletepromotion}
                        key={index}
                        data={promotion}
                        handlepromotionCheckboxChange={handlepromotionCheckboxChange}
                      />
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* <Pagination count={totalPages} page={currentPage} color="primary" onChange={handlePageChange}  className="d-flex justify-content-center"/> */}
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
        )}
        {/* end::Header */}
        {/* begin::Body */}

        {/* begin::Body */}
      </div>
    </>
  )
}
