import { Pagination } from '@mui/material';
import React, { useState } from 'react'
import { ListPromotions } from '../../components/promotions/ListPromotions';

export const Promotions = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    function handlePageChange(event:any, value:any) {
      console.log(value);
      
      setCurrentPage(value);
  
    }
    return (
      <>
              <ListPromotions states={{currentPage,setCurrentPage,totalItems,setTotalItems,setTotalPages}}/>
              <Pagination count={totalPages} page={currentPage} color="primary"  onChange={handlePageChange}  className="d-flex justify-content-center"/>
      </>
    )
}
