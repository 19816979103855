import React from 'react'
import { Loading } from '../Loading'
type Props = {
    data: any 
    // deleteVille:(id:number)=>void
  }
export const ModalProductPromotions: React.FC<Props> = ({data}) =>  {
  return (
    <div
        className='modal fade'
        id={'ModalPoductPromo' + data.id}
        tabIndex={-1}
        role='dialog'
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document' style={{maxWidth: '61%'}}>
          <div className='modal-content'>
            <div className='modal-header ' style={{background: 'var(--kt-primary)'}}>
              <h1 className='modal-title fs-5 text-white' id='exampleModalLabel'>
                Listes produit: 
              </h1>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body' style={{overflowY: 'scroll'}}>
              <form>
         


              
               
              
              </form>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                Annuler
              </button>
             
            </div>
          </div>
        </div>
      </div>
  )
}
