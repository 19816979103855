/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useCallApi} from '../../../../app/modules/utils'

type Props = {
  className: string
}

const TablesWidget5: React.FC<Props> = ({className}) => {
  const [monthSalesCategories, setMonthSalesCategories] = useState<any>(null)
  const callApi = useCallApi()

  const getSAllesCategories = async () => {
    try {
      const {data} = await callApi({
        route: 'api/month-sales-by-categorie',
        method: 'GET',
      })

      setMonthSalesCategories(data.vente_by_category)

      // console.log(data.data.today_sales_total_amount)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  useEffect(() => {
    getSAllesCategories()
  }, [])
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Vente par Catégorie</span>
        </h3>
        <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1'
                data-bs-toggle='tab'
                href='#kt_table_widget_5_tab_1'
              >
                Mois
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                data-bs-toggle='tab'
                href='#kt_table_widget_5_tab_2'
              >
                Semaine
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4'
                data-bs-toggle='tab'
                href='#kt_table_widget_5_tab_3'
              >
                Jour
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-150px'></th>
                    <th className='p-0 min-w-50px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {monthSalesCategories &&
                    monthSalesCategories.map((categorie: any, index: number) => {
                      return (
                        <tr key={index} className='border-top'>
                          <td>{categorie.name}</td>
                          <td></td>

                          <td className='text-end text-muted fw-semibold'></td>
                          <td className='text-end'></td>
                          <td className='text-end'>
                            <span className='badge badge-light-success'>{categorie.total} MAD</span>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          <div className='tab-pane fade' id='kt_table_widget_5_tab_2'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-150px'></th>
                    <th className='p-0 min-w-50px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {monthSalesCategories &&
                    monthSalesCategories.map((categorie: any, index: number) => {
                      return (
                        <tr key={index} className='border-top'>
                          <td>{categorie.name}</td>
                          <td></td>

                          <td className='text-end text-muted fw-semibold'></td>
                          <td className='text-end'></td>
                          <td className='text-end'>
                            <span className='badge badge-light-success'>{categorie.total} MAD</span>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          <div className='tab-pane fade' id='kt_table_widget_5_tab_3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-150px'></th>
                    <th className='p-0 min-w-50px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {monthSalesCategories &&
                    monthSalesCategories.map((categorie: any, index: number) => {
                      return (
                        <tr key={index} className='border-top'>
                          <td>{categorie.name}</td>
                          <td></td>

                          <td className='text-end text-muted fw-semibold'></td>
                          <td className='text-end'></td>
                          <td className='text-end'>
                            <span className='badge badge-light-success'>{categorie.total} MAD</span>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TablesWidget5}
