import React from 'react'
import { KTSVG } from '../../../_metronic/helpers';
import { ModalUpdateProduct } from '../produits/ModalUpdateProduct';
import { Link } from 'react-router-dom';


type Props = {
    data: any;
    handleProductCheckboxChange:any
  }
export const ItemStock: React.FC<Props> = ({ data,handleProductCheckboxChange }) => {
   console.log(data);
   
    return (
        <>
         <tr>
        
             
              <td>
              <div className="d-flex justify-content-start flex-column">
              <span className="text-dark fw-bold  d-block fs-6">
                        {data.productName}
                    </span>
                    <div    className='card-toolbar ml-3 mt-2'>
                    <span>
                    {data.productCode}
</span>

                   </div>
                  </div>
              </td>
              <td>
              <div className="d-flex justify-content-center text-center flex-column">
              <span className="text-dark fw-bold  d-block fs-6">
                        {data["en stock"]}
                    </span>
                  </div>
              </td>
              <td>
              <div className="d-flex justify-content-center flex-column">
                {
                    
                }
              <span className="text-dark fw-bold text-center d-block fs-6">
              <div    className='card-toolbar ml-3'>
              {/* ${dateObj.toISOString().slice(0, 10)} ${dateObj.toLocaleTimeString()} */}
                       {
                        data.entree
                       }
                           </div>
                      
                    </span>
                  </div>
              </td>
              <td>
              <div className="d-flex justify-content-center flex-column">
               
              <span className="text-dark fw-bold text-center d-block fs-6">
              <div    className='card-toolbar ml-3'>
              {data.sales } 
                           </div>
                      
                    </span>
                  </div>
              </td>
            
            </tr>
        
        </>
        
        
          )
}
