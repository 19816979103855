/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import {useCallApi} from '../../../../app/modules/utils'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const MixedWidget11: React.FC<Props> = ({className, chartColor, chartHeight}) => {
  const [yearSalles, setYearSalles] = useState<any>(null)
  const [totalSalles, setTotalSalles] = useState<any>(0)
  const callApi = useCallApi()

  const getSalles = async () => {
    try {
      const {data} = await callApi({
        route: 'api/today-sales-overall-report',
        method: 'GET',
      })
      setYearSalles(data.data.total_sales_by_month)
      setTotalSalles(data.data.today_sales_total_amount)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  useEffect(() => {
    getSalles()
  }, [])

  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const refreshChart = (data: any) => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, data))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart(yearSalles)

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, yearSalles])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
        {/* begin::Hidden */}
        <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
          <div className='me-2'>
            <span className='fw-bold text-gray-800 d-block fs-3'>Vente</span>
            {/* <span className='text-gray-400 fw-semibold'>Oct 8 - Oct 26 2021</span> */}
          </div>
          <div className={`fw-bold fs-3 text-${chartColor}`}>Ventes du jour: {totalSalles} MAD</div>
        </div>
        {/* end::Hidden */}

        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-10-chart'></div>
        {/* end::Chart */}
      </div>
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, yearSalles: any): ApexOptions => {
  if (!yearSalles) {
    return {
      series: [],
      chart: {
        fontFamily: 'inherit',
        type: 'line',
        height: chartHeight,
        toolbar: {
          show: false,
        },
      },
      // ... other default chart options
    }
  }

  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const secondaryColor = getCSSVariableValue('--kt-gray-300')
  const baseColor = getCSSVariableValue('--kt-' + chartColor)

  return {
    series: [
      {
        name: 'Total ventes',
        data: [
          yearSalles['1'],
          yearSalles['2'],
          yearSalles['3'],
          yearSalles['4'],
          yearSalles['5'],
          yearSalles['6'],
          yearSalles['7'],
          yearSalles['8'],
          yearSalles['9'],
          yearSalles['10'],
          yearSalles['11'],
          yearSalles['12'],
        ],
      },
    ],

    chart: {
      fontFamily: 'inherit',
      type: 'line',
      height: chartHeight,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 3,
      curve: 'smooth',
    },
    xaxis: {
      categories: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ],
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },

    yaxis: {
      labels: {
        formatter: function (value: any) {
          return value + '  MAD'
        },
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value: any) {
          return value + '  MAD'
        },
      },
    },
    colors: [baseColor],
    grid: {
      padding: {
        top: 10,
      },
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

export {MixedWidget11}
