/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import noUiSlider from 'nouislider'
import {useLayout} from '../../core'
import {KTSVG} from '../../../helpers'
import {AiOutlineSearch, AiOutlineMenu} from 'react-icons/ai'
import {BsFillEnvelopeFill} from 'react-icons/bs'
import {IoNotificationsSharp} from 'react-icons/io5'
import {FaLockOpen, FaCashRegister, FaWarehouse} from 'react-icons/fa'
import {DefaultTitle} from './page-title/DefaultTitle'
import {ThemeModeSwitcher} from '../../../partials'
import {Link} from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import useScanDetection from 'use-scan-detection'

const HeaderToolbar = () => {
  const {classes} = useLayout()
  const [status, setStatus] = useState<string>('1')
  const { logout,numCmd,setNumCmd} = useAuth()
  useScanDetection({
    onComplete: setNumCmd,
    minLength: 3
  })
  useEffect(() => {
    const rangeSlider = document.querySelector('#kt_toolbar_slider')
    const rangeSliderValueElement = document.querySelector('#kt_toolbar_slider_value')

    if (!rangeSlider || !rangeSliderValueElement) {
      return
    }

    // @ts-ignore
    noUiSlider.create(rangeSlider, {
      start: [5],
      connect: [true, false],
      step: 1,
      format: {
        to: function (value) {
          const val = +value
          return Math.round(val).toString()
        },
        from: function (value) {
          return value
        },
      },
      range: {
        min: [1],
        max: [10],
      },
    })

    // @ts-ignore
    rangeSlider.noUiSlider.on('update', function (values, handle) {
      rangeSliderValueElement.innerHTML = values[handle]
    })

    const handle = rangeSlider.querySelector('.noUi-handle')
    if (handle) {
      handle.setAttribute('tabindex', '0')
    }

    // @ts-ignore
    handle.addEventListener('click', function () {
      // @ts-ignore
      this.focus()
    })

    // @ts-ignore
    handle.addEventListener('keydown', function (event) {
      // @ts-ignore
      const value = Number(rangeSlider.noUiSlider.get())
      // @ts-ignore
      switch (event.which) {
        case 37:
          // @ts-ignore
          rangeSlider.noUiSlider.set(value - 1)
          break
        case 39:
          // @ts-ignore
          rangeSlider.noUiSlider.set(value + 1)
          break
      }
    })
    return () => {
      // @ts-ignore
      rangeSlider.noUiSlider.destroy()
    }
  }, [])

  return (
    <div className='toolbar d-flex align-items-stretch'>
      {/* begin::Toolbar container */}
      <div
        className={`${classes.headerContainer.join(
          ' '
        )} py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between flex-nowrap`}
      >

        <div className='d-flex align-items-center ' style={{width: '32%'}}>
          <div className='input-group w-100'>
            <input className="form-control " type="search"  aria-label="Search"   placeholder='Numéro de commande' value={numCmd} onChange={(e)=>setNumCmd(e.target.value)} />
            <div className='input-group-btn align-items-end d-grid'>
                <Link to={"/commandes"}>
                <button className='btn btn-primary' type='button'>
                <AiOutlineSearch />
              </button>
                </Link>
             
            </div>
          </div>
        </div>
        <div className='d-flex align-items-stretch overflow-auto pt-3 pt-lg-0 '>
          {/* begin::Action wrapper 
          <div className='d-flex align-items-center'>
            <button className='btn btn-success d-flex align-items-center fw-bold'>
              <FaLockOpen className='me-1 fw-bold' />
              Ouvert
            </button>
            {/* <button className='btn btn-primary d-flex align-items-center fw-bold ms-1'>
              <FaCashRegister className='me-1 fw-bold' />
              Caisse
            </button> 
            <button className='btn btn-primary d-flex align-items-center fw-bold ms-1'>
              <FaWarehouse className='me-1 fw-bold' />
              Mon Magasin
            </button>
            <button className='btn d-flex align-items-center fw-bold ms-1 btn position-relative'>
              <IoNotificationsSharp className=' fw-bold fs-3' />
              <span className='position-absolute top-10 start-75 translate-middle badge rounded-pill bg-danger'>
                0
              </span>
            </button>
            <button className='btn d-flex align-items-center fw-bold ms-1 btn position-relative'>
              <KTSVG
                path='/media/icons/duotune/general/envelope.svg'
                className='fs-1 text-white'
              ></KTSVG>
              <span className='position-absolute top-10 start-75 translate-middle badge rounded-pill bg-danger'>
                0
              </span>
            </button>
          </div>
          {/* end::Action wrapper */}

          {/* begin::Action wrapper */}
          <div className='d-flex align-items-center'>
            {/* begin::Separartor */}
            <div className='bullet bg-secondary h-35px w-1px mx-5'></div>
            {/* end::Separartor */}
          </div>
          {/* end::Action wrapper */}

          {/* begin::Action wrapper */}
          <div className='d-flex align-items-center'>
            {/* begin::Actions */}
            <div className='d-flex'>
              <button
                className='btn btn-icon btn-sm btn-active-color-primary mt-n2 text-white bg-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-start'
                data-kt-menu-overflow='false'
              >
                <AiOutlineMenu className='fw-bold fs-5 ' />
              </button>

              <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-3 fs-6 w-150px'
                data-kt-menu='true'
                data-popper-placement='bottom-start'
              >
                <div className='menu-item px-5'>
                   
                  <a className='menu-link px-3'onClick={logout} ><KTSVG
              path='/media/icons/duotune/arrows/arr076.svg'
              className='svg-icon-1 minimize-active'
            /> <span className=''>Deconnecter</span>  </a>
                </div>
              </div>
            </div>
            {/* end::Actions */}
          </div>
          {/* end::Action wrapper */}
        </div>
        {/* end::Toolbar container */}
      </div>
    </div>
  )
}

export {HeaderToolbar}
